<template>
  <transition name="fade">
    <div v-show="showLoader" class="loader"></div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ajax',
  computed: mapState(['ajax']),

  data() {
    return {
      showLoader: false,
    };
  },

  watch: {
    ajax() {
      if (this.$store.state.ajax) {
        this.showLoader = this.$store.state.ajax;
      } else {
        setTimeout(() => {
          this.showLoader = this.$store.state.ajax;
        }, 10);
      }
    },
  },
};
</script>

<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin-left: auto;
  font-size: 3px;
  text-indent: -9999em;
  border-top: 1.1em solid var(--main-color);
  border-right: 1.1em solid var(--main-color);
  border-bottom: 1.1em solid var(--main-color);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
