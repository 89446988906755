<template>
  <button class="button small" @click="remove">
    <svg
      class="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill-rule="evenodd"
      fill="#6e7072"
      clip-rule="evenodd"
    >
      <path
        d="M9 3h6v-1.75c0-.066-.026-.13-.073-.177-.047-.047-.111-.073-.177-.073h-5.5c-.066 0-.13.026-.177.073-.047.047-.073.111-.073.177v1.75zm11 1h-16v18c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-18zm-10 3.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v12c0 .276.224.5.5.5s.5-.224.5-.5v-12zm5 0c0-.276-.224-.5-.5-.5s-.5.224-.5.5v12c0 .276.224.5.5.5s.5-.224.5-.5v-12zm8-4.5v1h-2v18c0 1.105-.895 2-2 2h-14c-1.105 0-2-.895-2-2v-18h-2v-1h7v-2c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2h7z"
      />
    </svg>
  </button>
</template>

<script>
import { API_URL } from '../apiconfig';

export default {
  name: 'Bin',
  props: {
    messageID: String,
  },
  methods: {
    remove() {
      this.$store.commit('changeAjaxState', true);
      fetch(`${API_URL}/messages/delete?id=${this.messageID}`, {
        method: 'DELETE',
      }).then(() => {
        this.$emit('refresh');
        this.$store.commit('changeAjaxState', false);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  position: absolute;
  background: none;
  border: none;
  top: 1rem;
  right: 0.5rem;
  cursor: pointer;

  svg:hover {
    fill: #ff4200;
  }
}
</style>
